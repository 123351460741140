import i18n from '@i18n'

export const STANDARD_ORDER_STATUS = [
  {
    id: 1,
    name: i18n.t('standard_order.status_options.completed'),
  },
  {
    id: 2,
    name: i18n.t('standard_order.status_options.canceled'),
  },
]

export const STANDARD_PAYMENT_METHOD = [
  {
    id: 1,
    name: i18n.t('standard_order.payment_options.transfer'),
  },
  {
    id: 2,
    name: i18n.t('standard_order.payment_options.cash'),
  },
]

export const STANDARD_ORDER_LIST_CONSTANT = {
  "STANDARD_ORDER_STATUS" : STANDARD_ORDER_STATUS,
  "STANDARD_PAYMENT_METHOD" : STANDARD_PAYMENT_METHOD,
}

