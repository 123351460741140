import {ROUTES} from "@constants"
import i18n from "@/lang/i18n";

export default [
  {
    path: 'table-order',
    name: ROUTES.ADMIN.KYY_TABLE_ORDER_LIST,
    meta: {
      title: 'Category Menu',
      breadcrumb: [
        {
          'label': 'breadcrumbs.MasterTableOrderList',
        },
      ],
      activeKey: 'admin.table-order.list'
    },
    component: () => import("@pages/admin/kyy_table_order/TableOrderListPage"),
  },
  {
    path: 'table-order/create',
    name: ROUTES.ADMIN.KYY_TABLE_ORDER_CREATE,
    meta: {
      title: 'Category Menu',
      breadcrumb: [
        {
          'label': 'breadcrumbs.MasterTableOrderList',
          'href': '/admin/table-order',
        },
        {
          'label': 'breadcrumbs.MasterTableOrderCreate',
        },
      ],
      activeKey: 'admin.table-order.create'
    },
    component: () => import("@pages/admin/kyy_table_order/TableOrderCreateOrEdit"),
  },
  {
    path: 'table-order/:id/edit',
    name: ROUTES.ADMIN.KYY_TABLE_ORDER_EDIT,
    meta: {
      title: 'Category Menu',
      breadcrumb: [
        {
          'label': 'breadcrumbs.MasterTableOrderList',
          'href': '/admin/table-order',
        },
        {
          'label': 'breadcrumbs.MasterTableOrderUpdate',
        },
      ],
      activeKey: 'admin.table-order.edit'
    },
    component: () => import("@pages/admin/kyy_table_order/TableOrderCreateOrEdit"),
  },
  {
    path: 'table-statistics',
    name: ROUTES.ADMIN.KYY_TABLE_ORDER_BY_MONTHS,
    meta: {
      title: 'StudentOrder',
      breadcrumb: [
        {
          'label': 'breadcrumbs.MasterTableOrderList',
        },
      ],
      activeKey: 'admin.student_order.statistics_by_months'
    },
    component: () => import("@pages/admin/kyy_table_order/TableOrderStatisticsPage"),
  },
];
