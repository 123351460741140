<template>
  <div :id="richTextId" v-html="val">{{val}}</div>
</template>

<script>
  export default {
    name: "AppSummerNote",
    props: {
      maxLength: String,
      val: String,
      uploadS3: Boolean,
      richTextId: {
        type: String,
        default: 'summernote'
      },
    },
    // watch : {
    //   val: {
    //     // the callback will be called immediately after the start of the observation
    //     immediate: true,
    //     handler (val, oldVal) {
    //       console.log(val)
    //       $(inputId).summernote("code", val);
    //     }
    //   }
    // },
    data() {
      return {
        plans: [],
        facilityId: this.$route.params.id
      };
    },
    async mounted() {
      if(this.facilityId != undefined) {
        let res = await this.$request.get(this.ENDPOINT.UI_HELPER_FACILITY_PLAN_LIST(this.facilityId));
        if (!res.hasErrors()) {
          this.plans = res.data;
        }
      }
      this.register();
    },
    methods: {
      register() {
        const inputId = '#' + this.richTextId
        $(inputId).summernote("destroy")
        $(inputId).find(".note-editor").remove()
        $(inputId).summernote({
          callbacks: {
            onImageUpload: async (image)   =>  {
              var sizeKB = image[0]['size'];
              if((sizeKB / (1024 *1024)) > 5){
                this.__noticeError("最大5MBまでの画像を指定してください。");
                return;
              }

              if(image[0]['type'] != 'image/jpeg' && image[0]['type'] != 'image/png'){
                this.__noticeError("最大5MBまでの JPEG, PNG 画像を指定してください。");
                return;
              }
              let object = URL.createObjectURL(image[0])
              try {
                let fileInfo = await this.getWithHeight(object)
                if (!(fileInfo.width <= 1600 && fileInfo.height <= 900)) {
                  this.__noticeError("縦900px、横1600pxにおさまるサイズの画像を指定してください。");
                  return;
                }
              } catch (e) {
                this.__noticeError("最大5MBまでの JPEG, PNG 画像を指定してください。");
                return;
              }
              var file = image[0];
              if (this.uploadS3) {
                await this.uploadFile(file)
              } else {
                var reader = new FileReader();
                reader.onloadend = function() {
                  var image = $('<img>').attr('src',  reader.result);
                  $(inputId).summernote("insertNode", image[0]);
                }
                reader.readAsDataURL(file);
              }
            },
            onChange: (contents, $editable) => {
              let data = contents.replace(/(<p>)+/ig, "")
              data = data.replace(/(<br>)+/ig, "")
              data = data.replace(/(<\/p>)+/ig, "")
              data =  data.replace(/(&nbsp;)+/ig, '');
              data = data.trim();
              var isEmpty = (data === '')
              $(inputId).val( isEmpty ? "" : contents);
              let imageCount = 0;
              try {
                imageCount = $(contents).find('img').length;
              }catch (e) {
                console.log('image Count = 0');
              }
              if (imageCount > 5) {
                $(inputId).summernote("code", this.val);
                this.__noticeError("画像は最大5枚までアップロードできます。");
              } else {
                this.$emit('input',  isEmpty ? "" : contents);
              }
            },

            onKeydown: (e) => {
              var t = e.currentTarget.innerText;
              if (t.length >= this.maxLength) {
                //delete keys, arrow keys, copy, cut
                if (e.keyCode != 8 && !(e.keyCode >= 37 && e.keyCode <= 40) && e.keyCode != 46 && !(e.keyCode == 88 && e.ctrlKey) && !(e.keyCode == 67 && e.ctrlKey))
                  e.preventDefault();
              }
            },
            onImageUploadError: (msg) =>{
              this.__noticeError("コンテンツ編集欄の入力に含まれるURLの書式が正しくありません。");
            },

            onPaste :(e) => {
              var t = e.currentTarget.innerText;
              var bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');
              if ((t.length + bufferText.length ) > this.maxLength) {
                e.currentTarget.innerText =  e.currentTarget.innerText + bufferText.substring(0, this.maxLength - t.length - 1)
                e.preventDefault();
              }
            }
          },
          tabsize: 2,
          height: 500,
          toolbar: [
            ['style', ['style']],
            ['font', ['bold', 'italic', 'underline', 'clear']],
            ['fontname', ['fontname']],
            ['fontsize', ['fontsize']],
            ['color', ['color']],
            ['para', ['ul', 'ol', 'paragraph']],
            ['height', ['height']],
            ['table', ['table']],
            ['insert', ['link', 'picture']],
            ['view', ['codeview', 'help']]
          ],
          hint: {
            mentions: this.plans,
            match: /\B@(\w*)$/,
            search: function (keyword, callback) {
              callback($.grep(this.mentions, function (item) {
                return item.name.indexOf(keyword) == 0;
              }));
            },
            template: function (item) {
              return item.name;
            },
            content: function (item) {
              // return $('<a href="' + item.url + '" class="mentionned mentioned-'+item.guid+'">@' + item.name + '</a>')[0];
              return $('<a href="' + item.url +'" target="_blank">' + item.name + '</a>')[0];
            }
          }
        });

        $(".note-modal-footer input").css({"position": "absolute", "bottom": "10px", "right": "30px"});
        $(".note-modal-body .sn-checkbox-open-in-new-window").hide();
        $(".note-modal-body .sn-checkbox-use-protocol").hide();

        $(".note-insert").find(`[aria-label='Video']`).on('click', function (){
          $(".note-video-url").val("")
        });
      },
      async uploadFile(file) {
        let res = null;
        let data = new FormData();
        data.append("file", file);
        res = await this.$request.post(this.ENDPOINT.SUMMERNOTE_UPLOAD_IMAGE, data);
        if (res.hasErrors()) {
          this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
        } else {
          $(inputId).summernote("editor.insertImage", res.data.url);
          // this.__noticeSuccess("ok");
        }
      }
    }
  }
</script>

<style>
  .note-editor .note-btn > span.note-icon-caret,
  .note-editor .note-btn > i > span.note-icon-caret {
    display: none;
  }
  .note-editor .note-btn-group .note-btn {
    height: 32.17px;
  }
  .note-editor .note-toolbar .note-color .dropdown-toggle,
  .note-editor .note-popover .popover-content .note-color .note-btn.dropdown-toggle {
    width: 26px;
  }
  .note-editor .note-para .note-dropdown-menu .note-btn {
    font-size: 12px;
  }
  .note-editor .note-color .btn.note-color-reset,
  .note-editor .note-color .btn.note-color-select {
    height: auto !important;
    margin: 3px 0 !important;
  }
  .note-editor .note-color .note-dropdown-menu .note-palette:first-child {
    margin: 0 5px 0 0 !important;
  }
</style>
