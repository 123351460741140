import ja from "vee-validate/dist/locale/ja.json";

ja.names = {
  name: 'name',
  name_en: 'Name',
  email: 'email',
  phone: "phone",
  password: 'Password',
  password_confirm: 'パスワード（確認）',
  role: "ロール",
  address: "住所",
  fax: "FAX",
  new_password: "パスワード",
  new_password_confirm: "パスワード（確認）",
  current_password: "現在のパスワード",
  current_email: "現在のメールアドレス",
  new_email: "新メールアドレス",
  start_date: "開始日",
  end_date: "終了日",
  login_email: "email",
  price: "料金",
  postal_code: "郵便番号",
  contact_postal_code: "郵便番号",
  prefecture: "都道府県",
  status_enabled: "ステータス",
  apartment_id: "マンション",
  name_kana: "フリガナ",
  line_code: "LINEコード",
  phone_number: "電話番号",
  blood_type: "血液型",
  blood_rh: "RH",
  gender: "性別",
  user_number: "利用者番号",
  room_no: "部屋",
  birthday: "生年月日",
  contact_phone: "電話番号",
  contact_cellular: "携帯番号",
  phone_cellular: "携帯番号",
  phone_fax: "FAX番号",
  customer_member_id: "会員ID",
  served_at : "摂取日時",
  amount_of_main : "摂取量（主食)",
  amount_of_sub : "摂取量（副食)",
  amount_of_soup : "摂取量（汁物)",
  type_of_meal : "食事の形態",
  type_of_three_meals : "食事の種類",
  name_of_drink : "水分の名称",
  amount_of_drink : "摂取量",
  name_snack : "食事の名称",
  amount_snack : "摂取量",
  recorded_at: '記録日時',
  type_of_meal_snack : "食事の種類",
  customer_id : "会員ID",
  temperature : "体温",
  situation : '状況',
  pressure_high : '血圧（高）',
  pressure_low : '血圧（低）',
  pulse_rate : '脈拍',
  respiratory_rate : '呼吸',
  irregular_pulse : '不整脈',
  blood_sugar : '血糖値',
  spo2 : 'SPO2',
  serial_id : "管理番号",
  description : "説明文",
  status_disp_crowded : "混雑確認ステータス",
  status_disp_guide : "施設案内ステータス",
  status_disp_signage : "サイネージ表示",
  order_disp_crowded : "混雑確認表示順",
  order_disp_guide : "施設案内表示順",
  web_url : "Web URL",
  image : "写真",
  browse : "参照",
  booking_flow_type : "予約者の画面順序",
  label_booking_flow_type : "※予約スケジュールにて空き状況を確認することができます。",
  gateway_disp_type : "表示方式",
  gateway_threshold_available_max : "空いている状況の上限数",
  gateway_threshold_crowded_max : "やや混んでいる状況の上限数",
  gateway_available_api_id : "館内施設API ID",
  gateway_available_gateway_serial : "ゲートウェイ管理番号",
  gateway_unit_time : "混雑表示の時間間隔",
  mansion_id : "マンション",
  detail_button_status : "ボタン表示",
  detail_content : "編集画面",
  facility_id : '館内施設',
  opening_time : '開始時間',
  closing_time : '終了時間',
  unit_time : '予約の時間単位',
  unit_capacity : '予約可能人数',
  limit_booking_hours : '予約者の利用制限',
  limit_booking_times : '回',
  work_days : '営業日',
  title : "タイトル",
  status_publishing : "ステータス",
  information_type : "記事の種類",
  image_path : "写真",
  display_order : "表示順",
  note : "備考",
  plan_price : "価格（税抜）",
  minutes : "分単位",
  plan_unit_minutes : "プラン利用時間",
  reservation_date : "日付",
  reservation_start_time : "開始時間",
  reservation_close_time : "終了時間",
  customer_name : "名前",
  plan_id : "プラン",
  number_of_people : "予約人数",
  customer_phone : "電話番号",
  repeat_type : "繰り返し期限",
  repeat_custom_wdays : "繰り返し設定",
  reservation_pick_date_of_week : "カスタムの場合",
  order_diswsp_crowded : "混雑確認表示順",
  book_master_id : "予約マスタ選択",
  number_of_peoples : "設定数",
  beacon_id : "ビーコンID",
  serial : "管理番号",
  signal : "信号強度",
  mac_id : "Mac ID",
  big_category_id: "大カテゴリ",
  medium_category_id: "中カテゴリ",
  category_id: "小カテゴリ",
  summernote : "回答の編集",
  receipt_number: "領収書番号",
  received_from: "宛名",
  order_id: "注文番号",
  date : "日付",
  name_last: "名字（姓）",
  name_first : "名前（名）",
  kana_last : "カナ（姓）",
  kana_first : "カナ（名）",
  is_reduced_tax : "軽減税率",
  plan_tax : "消費税額",
  smaregi_store_id : "スマレジ店舗",
  smaregi_table_id : "スマレジ店舗のテーブル",
  limit_booking_hour : "有効な時間",
  limit_cancel_hour : "有効な時間",
  limit_order_hour : "有効な時間",
  limit_booking_in_unit_time: "予約可能件数",
  menu_id: "メニュー",
  quantity: "数量",
  hour: "注文時間",
  information_id: "ルームサービス",
  space_number: "館内施設数",

  // kyy
  restaurant_name: "Tên nhà hàng",
  restaurant_address: "Địa chỉ tiếng Nhật",
  restaurant_address_vn: "Địa chỉ tiếng Việt",
  restaurant_hotline: "Hotline nhà hàng",
  restaurant_bank_number: "Số tài khoản",
  restaurant_bank_name: "Tên Ngân hàng",
  restaurant_bank_account_name: "Tên chủ tài khoản",
  restaurant_logo: "Logo nhà hàng",
  kyy_menu_description: "Mô tả tiếng Nhật",
  kyy_menu_description_vn: "Mô tả tiếng Việt",
  kyy_menu_name: "Tên món ăn tiếng Nhật",
  kyy_menu_name_vn: "Tên món ăn tiếng Việt",
  kyy_menu_sell_date: "Ngày bán",
  kyy_menu_price_s: "Giá tiền size S",
  kyy_menu_price_m: "Giá tiền size M",
  kyy_menu_price_l: "Giá tiền size L",
  kyy_menu_image: "Hình ảnh món ăn",

  kyy_category_name: "Tên danh mục tiếng Nhật",
  kyy_category_name_vn: "Tên danh mục tiếng Việt",
  kyy_category_image: "Hình ảnh danh mục",

  kyy_category_menu_name: "Tên món ăn tiếng Nhật",
  kyy_category_menu_ids: "Món ăn",
  kyy_category_menu_name_vn: "Tên món ăn tiếng Việt",
  kyy_category_menu_image: "Hình ảnh món ăn",
  kyy_category_menu_description: "Mô tả món ăn tiếng Nhật",
  kyy_category_menu_description_vn: "Mô tả món ăn tiếng Việt",
  kyy_category_menu_price: "Giá tiền",

  kyy_table_order_customer_name: "Tên khách hàng",
  kyy_table_order_phone_number: "Số điện thoại",
  kyy_table_order_guest_number: "Số lượng khách",
  kyy_table_order_order_time: "Thời gian đặt",
  kyy_table_order_status: "Trạng thái bàn",
  kyy_table_order_note: "Ghi chú",

  kyy_news_category_name: "Tên danh mục",

  kyy_news_category: "Danh mục",
  kyy_news_title: "Tiêu đề tiếng Nhật",
  kyy_news_title_vn: "Tiêu đề tiếng Việt",
  kyy_news_content: "Nội dung tiếng Nhật",
  kyy_news_content_vn: "Nội dung tiếng Việt",
  kyy_news_thumbnail: "Ảnh thumbnail",

  kyy_customers_user_name: "Mã đăng nhập",
  kyy_customers_email: "Email",
  kyy_customers_password: "Mật khẩu",
  kyy_customers_phone_number: "Số điện thoại",
  kyy_customers_customer_name: "Tên khách hàng",
  kyy_customers_student_name: "Tên học sinh",
  kyy_customers_student_class: "Lớp",
};

ja.messages = {
  ...ja.messages,
  "alpha": "{_field_}はアルファベットのみ使用できます。",
  "alpha_num": "{_field_}は英数字のみ使用できます。",
  "alpha_dash": "{_field_}は英数字とハイフン、アンダースコアのみ使用できます。",
  "alpha_spaces": "{_field_}はアルファベットと空白のみ使用できます。",
  "between": "{_field_}は{min}から{max}の間でなければなりません。",
  "digits_between": "{_field_}は{min}~{max}桁の数字で入力してください。",
  "confirmed": "{_field_}が一致しません。",
  "digits": "{_field_}は{length}桁の数字でなければなりません。",
  "dimensions": "{_field_}は幅{width}px、高さ{height}px以内でなければなりません。",
  "email": "Please specify a valid email address.",
  "excluded": "{_field_}は不正な値です。",
  "ext": "{_field_}は有効なファイル形式ではありません。",
  "image": "{_field_}は有効な画像形式ではありません。",
  "integer": "{_field_}は整数のみ使用できます。",
  "is": "{_field_}が一致しません。",
  "is_not": "{_field_}は無効です。",
  "length": "半角数字{length}桁の形式で入力してください。",
  "max_value": "{_field_}は{max}以下の値を入力してください。",
  "double42": "{_field_}は99.99以下の値を入力してください。",
  "double52": "{_field_}は999.99以下の値を入力してください。",
  "max": "{_field_}は{length}文字以内にしてください。",
  "mimes": "{_field_}は有効なファイル形式ではありません。",
  "min_value": "{_field_}は{min}以上の値を入力してください。",
  "min": "{_field_}は{length}桁の数字で入力してください。",
  "numeric": "{_field_}は数字のみ使用できます。",
  "oneOf": "{_field_}は有効な値ではありません。",
  "regex": "{_field_}のフォーマットが正しくありません。",
  "required": "Please enter a {_field_}",
  "required_if": "こちらは必須項目です。",
  "size": "{_field_}は{size}KB以内でなければなりません。",
  "double": "{_field_}double",
  "decimal": "{_field_}decimal",
  "katakana": "{_field_}に使用できない文字があります。",
  required_noname: 'こちらは必須項目です。',
  password: 'パスワードは8文字以上~20文字以下半角英数字で、大文字、小文字、数字を全て含んでいる必要があります。',
  phone: '電話番号は10～11桁の数字で入力してください。',
  phone_number: '{_field_}は10～11桁の数字で入力してください。',
  phone_home: '自宅番号は半角数字10桁の形式で入力してください。',
  phone_mobile: '携帯番号は半角数字11桁の形式で入力してください。',
  fax: "FAXは半角数字10桁の形式で入力してください。",
  ja_postal_code: "有効な郵便番号を入力してください。",
  email_unique: "このメールアドレスは既に使用されています。",
  confirm_pass: "パスワード（確認） とパスワードの入力が一致しません。",
  opening_time:"{_field_}は終了時間以前の時間を入力してください。",
  min_limit_booking_hour:"利用制限の時間は{min}以上の数字を入力するか空白（無制限）としてください。",
  min_limit_booking_times:"利用制限の回数は{min}以上の数字を入力するか空白（無制限）としてください。",
  hour_minute: '有効な{_field_}を入力してください。',

  // override
  phone_number: 'Vui lòng nhập {_field_} là một dãy số gồm 10 đến 11 chữ số.',
}

export default ja
