import {ROUTES} from "@constants"
import i18n from "@/lang/i18n";

export default [
  {
    path: 'home-setting',
    name: ROUTES.ADMIN.KYY_HOME_SETTING,
    meta: {
      title: 'HomeSetting',
      breadcrumb: [
        {
          'label': 'breadcrumbs.MasterHomeSetting',
        },
      ],
      activeKey: 'admin.home_setting'
    },
    component: () => import("@pages/admin/kyy_home_setting/HomeSetting"),
  },
];
