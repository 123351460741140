import {ROUTES} from "@constants"

export default [
  {
    path: 'standard-orders',
    name: ROUTES.ADMIN.KYY_STANDARD_ORDER_LIST,
    meta: {
      title: 'StandardOrder',
      breadcrumb: [
        {
          'label': 'breadcrumbs.MasterStandardOrderList',
        },
      ],
      activeKey: 'admin.standard_order.list'
    },
    component: () => import("@pages/admin/kyy_standard_order/StandardOrderListPage"),
  },
  {
    path: 'standard-statistics',
    name: ROUTES.ADMIN.KYY_STANDARD_STATISTICS,
    meta: {
      title: 'StandardOrder',
      breadcrumb: [
        {
          'label': 'breadcrumbs.MasterStandardOrderStatistics',
        },
      ],
      activeKey: 'admin.standard_order.statistics' 
    },
    component: () => import("@pages/admin/kyy_standard_order/StandardOrderStatistics"),
  }
];
