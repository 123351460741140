import {ROUTES} from "@constants"
import i18n from "@/lang/i18n";

export default [
  {
    path: 'news-categories',
    name: ROUTES.ADMIN.KYY_NEWS_CATEGORY_LIST,
    meta: {
      title: 'NewsCategory',
      breadcrumb: [
        {
          'label': 'breadcrumbs.MasterNewsCategoryList',
        },
      ],
      activeKey: 'admin.news_category.list'
    },
    component: () => import("@pages/admin/kyy_news_category/NewsCategoryListPage"),
  },
  {
    path: 'news-categories/create',
    name: ROUTES.ADMIN.KYY_NEWS_CATEGORY_CREATE,
    meta: {
      title: 'NewsCategory',
      breadcrumb: [
        {
          'label': 'breadcrumbs.MasterNewsCategoryList',
          'href': '/admin/news-categories',
        },
        {
          'label': 'breadcrumbs.MasterNewsCategoryCreate',
        },
      ],
      activeKey: 'admin.news_category.create'
    },
    component: () => import("@pages/admin/kyy_news_category/NewsCategoryCreateOrEdit"),
  },
  {
    path: 'news-categories/:id/edit',
    name: ROUTES.ADMIN.KYY_NEWS_CATEGORY_EDIT,
    meta: {
      title: 'NewsCategory',
      breadcrumb: [
        {
          'label': 'breadcrumbs.MasterNewsCategoryList',
          'href': '/admin/news-categories',
        },
        {
          'label': 'breadcrumbs.MasterNewsCategoryUpdate',
        },
      ],
      activeKey: 'admin.news_category.edit'
    },
    component: () => import("@pages/admin/kyy_news_category/NewsCategoryCreateOrEdit"),
  },
];
