import _ from "lodash";
import { ENDPOINT, HTTP_CODES, METHODS } from '@constants/api'
import { AMOUNTS, TYPE_OF_THREE_MEALS, TYPE_OF_SNACK, FILTER_TYPE_THREE_MEALS, TYPES} from '@constants/customer_meals'
import { ROLES, ROUTES, AUTH, VALIDATE, APP_NAME, APP_VERSION_URL, BASIC_TABLE } from '@constants'
import {FACILITIES_CONSTANT} from '@constants/facilities'
import {INFORMATION_CONSTANT} from '@constants/information'
import {PLAN_CONSTANT} from '@constants/plan'
import * as pluginCommon from '@utils'
import {RESERVATION_CONSTANT} from '@constants/reservation'
import {BEACONS_CONSTANT} from '@constants/beacons'
import {GATEWAY_CONSTANT} from '@constants/gateway'
import {MENU_CONSTANT} from '@constants/menu'
import {RESTAURANTS_CONSTANT} from '@constants/restaurants'
import {ORDER_LIST_CONSTANT} from '@constants/order_list'
import {SALE_CONSTANT} from '@constants/sale'
import {RECEIPTS_CONSTANT} from '@constants/receipts'
import {CSV_DOWNLOAD_CONSTANT} from '@constants/csv_download'
import {ANNOUNCEMENTS_MNG_CONSTANT} from '@constants/announcement_mng'
import {TRANSPORTS_LIST_CONSTANT} from '@constants/transport_list'

// kyy
import {STUDENT_ORDER_LIST_CONSTANT} from '@constants/kyy_student_order_list'
import {STUDENT_INVOICE_CONSTANT} from "@constants/kyy_student_invoice";
import {TABLE_ORDER_CONSTANT} from "@constants/kyy_table_order";
import { STANDARD_ORDER_LIST_CONSTANT } from "@constants/kyy_standard_order_list";

/* Forms */
import AppInput from "@components/forms/AppInput";
import AppSelect from "@components/forms/AppSelect";
import AppRadio from "@components/forms/AppRadio";
import AppDatepicker from "@components/forms/AppDatepicker";
import AppSelectMulti from "@components/forms/AppSelectMulti";
import AppText from "@components/forms/AppTextarea";
import AppTimepicker from '@components/forms/AppTimepicker'
import AppDatePicker from '@components/forms/AppDatepicker';
import AppPassword from '@components/forms/AppPassword';
import AppSummernote from '@components/forms/AppSummernote';
import AppDateRangePicker from '@components/forms/AppDateRangepicker';
/* Others */
import AppModalConfirm from "@components/_common/popup/AppModalConfirm";
import AppModal from "@components/_common/popup/AppModal";
import AppHeaderNotice from "@components/_common/AppHeaderNotice";
import AppPaginate from "@components/_common/AppPaginate";
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import AppModalCustomer from "../components/_common/popup/AppModalCustomer";
import AppDateRangepicker from "../components/forms/AppDateRangepicker";
import AppSingleFileInput from "../components/forms/AppSingleFileInput";
import AppCheckBox from "../components/forms/AppCheckbox";

export default {
    data() {
        return {
            ENDPOINT,
            HTTP_CODES,
            METHODS,
            ROLES,
            ROUTES,
            AUTH,
            VALIDATE,
            APP_NAME,
            APP_VERSION_URL,
            BASIC_TABLE,
            AMOUNTS,
            TYPE_OF_THREE_MEALS,
            TYPE_OF_SNACK,
            FILTER_TYPE_THREE_MEALS,
            TYPES,
            FACILITIES_CONSTANT,
            INFORMATION_CONSTANT,
            PLAN_CONSTANT,
            RESERVATION_CONSTANT,
            BEACONS_CONSTANT,
            GATEWAY_CONSTANT,
            MENU_CONSTANT,
            RESTAURANTS_CONSTANT,
            ORDER_LIST_CONSTANT,
            SALE_CONSTANT,
            RECEIPTS_CONSTANT,
            CSV_DOWNLOAD_CONSTANT,
            ANNOUNCEMENTS_MNG_CONSTANT,
            TRANSPORTS_LIST_CONSTANT,

            // kyy
            STUDENT_ORDER_LIST_CONSTANT,
            STUDENT_INVOICE_CONSTANT,
            TABLE_ORDER_CONSTANT,
            STANDARD_ORDER_LIST_CONSTANT,
            _,
        }
    },

    components: {
        "app-modal": AppModal,
        "app-modal-confirm": AppModalConfirm,
        "app-modal-customer": AppModalCustomer,
        "app-select": AppSelect,
        "app-select-multi": AppSelectMulti,
        "app-input": AppInput,
        "app-timepicker": AppTimepicker,
        "app-text": AppText,
        "app-date-picker": AppDatePicker,
        "app-datepicker": AppDatepicker,
        "app-date-range-picker": AppDateRangepicker,
        "app-radio": AppRadio,
        "validation-observer": ValidationObserver,
        "validation-provider": ValidationProvider,
        "app-header-notice": AppHeaderNotice,
        "app-paginate": AppPaginate,
        "app-password": AppPassword,
        "app-summernote" : AppSummernote,
        "app-single-file-input" : AppSingleFileInput,
        "app-check-box" : AppCheckBox
    },

    methods: {
        ...pluginCommon,

        responseOk(code) {
            return code === HTTP_CODES.SUCCESS;
        },

        generateUrl(name, params, query) {
            let props = router.resolve({ name, params, query });
            return `${window.location.origin}${props.href}`;
        },
    },
};
