export const BASE_URL = 'https://dev.kyykhang.com';
export const BASE_URL_API = 'https://dev.kyykhang.com/api';
export const ADMIN_URL_API = 'https://dev.kyykhang.com/api/admin';

export const METHODS = {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    PATCH: "PATCH",
    DELETE: "DELETE"
};

export const HTTP_CODES = {
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOTFOUND: 404,
    SUCCESS: 200
};

export const PERMISSION = {
    DENIED : 'PERMISSION_DENIED'
};
export const RATE_TAX = 10;
export const MIN_LENGTH_QR_CODE_LINE = 4;
export const ENDPOINT = {
    AUTH_LOGIN: '/auth/login',
    AUTH_LOGOUT: '/auth/logout',
    AUTH_REFRESH: '/auth/refresh',
    AUTH_USER: '/auth/me',
    AUTH_FORGOT_PASSWORD: '/auth/forgot-password',
    AUTH_RESET_PASSWORD: '/auth/reset-password',
    AUTH_CHECK_PARAMS_RESET_PASSWORD: '/auth/check-params-reset-password',

    PROFILE_USER: '/profile/user',
    PROFILE_UPDATE: '/profile/update_profile',

    // For common
    UI_HELPER_GET_POSTAL_CODE: zipcode_id => `ui-helper/get-postal-code/${zipcode_id}`,
    UI_HELPER_APARTMENT: 'ui-helper/apartments',
    UI_HELPER_CUSTOMER: 'ui-helper/customers',
    UI_HELPER_CUSTOMER_VITAL: 'ui-helper/customer-vitals',
    ADDRESS_BY_ZIPCODE : postalCode => `prefectures/get-by-postal-code/${postalCode}`,
    UI_HELPER_ACCOUNTS : 'ui-helper/accounts',
    UI_HELPER_VALIDATE_UNIQUE : 'ui-helper/validate-unique',
    UI_HELPER_CUSTOMER_MEALS : 'ui-helper/customer-meals',
    UI_HELPER_BOOK_MASTERS : 'ui-helper/book-masters',
    UI_HELPER_TRANSPORT_CAR_MASTERS : 'ui-helper/transport-car-master',
    UI_HELPER_APARTMENTS: apartment_id => `ui-helper/apartments/${apartment_id}`,
    UI_HELPER_BOOK_MASTERS_FACILITY_LIST : apartment_id => `ui-helper/book-masters/facilities/${apartment_id}`,
    UI_HELPER_FACILITIES : 'ui-helper/facilities',
    UI_HELPER_FACILITIES_BIZ_TYPE : (apartment_id, biz_type) => `ui-helper/facilities/biz/${biz_type}/${apartment_id}`,
    UI_HELPER_FACILITY_PLAN_LIST : facility_id => `ui-helper/facilityPlans/${facility_id}`,
    UI_HELPER_PLANS : facility_id => `ui-helper/plans/${facility_id}`,
    UI_HELPER_RESERVATION : 'ui-helper/reservations',
    UI_HELPER_GATEWAYS : 'ui-helper/gateways',
    UI_HELPER_CHAT_BOT_CATEGORY : 'ui-helper/qa-category',
    UI_HELPER_MENU : '/ui-helper/menus',
    UI_HELPER_ORDER_LIST : 'ui-helper/order-list',
    UI_HELPER_RESTAURANTS_FACILITY : facility_id => `ui-helper/restaurants/facilities/${facility_id}`,
    UI_HELPER_RESTAURANTS_STORE : id => `ui-helper/restaurants/${id}`,
    UI_HELPER_RESTAURANTS_GET_QR_CODE:  `admin/ui-helper/restaurants/get-qr-code`,
    UI_HELPER_RESTAURANTS_SMAREGI : `ui-helper/restaurants/smaregi`,
    UI_HELPER_RESTAURANTS_FACILITY_LIST : apartment_id => `ui-helper/restaurants/list-facility/${apartment_id}`,
    UI_HELPER_RECEIPTS : '/ui-helper/receipts',
    UI_HELPER_RECEIPTS_GET_LIST_ORDER : '/ui-helper/get-order-list',
    UI_HELPER_ANNOUNCEMENTS : announcement_id => `ui-helper/announcements/${announcement_id}`,
    UI_HELPER_SEND_MAIL: announcement_id => `ui-helper/notify/${announcement_id}`,
    UI_HELPER_TRANSPORTS_LIST : 'ui-helper/transport-list',
    UI_HELPER_GET_LAST_APARTMENT : 'ui-helper/get-last-apartment',
    UI_HELPER_TRANSPORTS_CAR: transport_car_id => `ui-helper/transport-car/${transport_car_id}`,
    UI_HELPER_LIST_TEMPLATE_CSV : 'ui-helper/list_template_csv',
    UI_HELPER_SALES: 'ui-helper/sales',
    UI_HELPER_SALES_CUSTOMER: customer_id => `ui-helper/sales/customer/${customer_id}`,
    SUMMERNOTE_UPLOAD_IMAGE : 'editor/upload',
    // For account mgmt
    RESENT_ACTIVE_EMAIL: userId => `resent_active_email/${userId}`,
    RESENT_ACTIVE_EMAIL_ADMIN: userId => `resent_active_email_admin/${userId}`,
    USERS_LIST: '/users',
    USER_CREATE: '/users',
    USERS_DELETE: id => `/users/${id}`,
    USERS_EDIT: id => `/users/${id}`,
    USERS_EDIT_PASSWORD: '/users/handle_edit_password',
    USERS_SAVE: '/users',
    VERIFY_EMAIL: (id, token) => `admin/verify-email/${id}/${token}`,
    VERIFY_EMAIL_RESENT: (id, token) => `admin/verify-resend-email/${id}/${token}`,
    VERIFY_PASSWORD: (id, token, adminUpdateId) => `admin/verify-edit-password/${id}/${token}/${adminUpdateId}`,
    VERIFY_ACTIVE_USER: (id) => `admin/verify-active-user/${id}`,

    // For Apartment mgmt
    APARTMENT_LIST: `/apartments`,
    APARTMENT_CREATE: `/apartments`,
    APARTMENT_EDIT: id =>  `/apartments/${id}`,
    APARTMENT_DELETE: id => `/apartments/${id}`,
    // For Apartment Api Key mgmt
    APARTMENT_API_KEY_LIST: `apartments/api_keys`,
    APARTMENT_API_KEY_CREATE: `apartments/api_keys`,
    APARTMENT_API_KEY_EDIT: id => `apartments/api_keys/${id}`,
    APARTMENT_API_KEY_DELETE: id => `apartments/api_keys/${id}`,

    APARTMENT_TRANSPORT_AREA_LIST: `apartments/transport_area`,
    APARTMENT_TRANSPORT_AREA_CREATE: `apartments/transport_area`,
    APARTMENT_TRANSPORT_AREA_EDIT: id => `apartments/transport_area/${id}`,
    APARTMENT_TRANSPORT_AREA_DELETE: id => `apartments/transport_area/${id}`,

    // For Customer mgmt
    CUSTOMER_LIST: `/customers`,
    CUSTOMER_CREATE: `/customers`,
    CUSTOMER_EDIT: id =>  `/customers/${id}`,
    CUSTOMER_DELETE: id => `/customers/${id}`,
    CUSTOMER_USER_NUMBER: userNumber => `/customers/user-number/${userNumber}`,
    CUSTOMER_CHECK_LINE_CODE : `customers/check-line-code`,

    // For Customer Vital mgmt
    CUSTOMER_VITAL_LIST: `/customer-vitals`,
    CUSTOMER_VITAL_CREATE: `/customer-vitals`,
    CUSTOMER_VITAL_EDIT: id =>  `/customer-vitals/${id}`,
    CUSTOMER_VITAL_DELETE: id => `/customer-vitals/${id}`,

    // For Customer meal mgmt
    CUSTOMER_MEAL_LIST: `/customer-meals`,
    CUSTOMER_MEAL_CREATE: `/customer-meals`,
    CUSTOMER_MEAL_EDIT  : id =>  `/customer-meals/${id}`,
    CUSTOMER_MEAL_UPDATE  : id =>  `/customer-meals/${id}`,

    //For Book Master Mgmt
    BOOK_MASTERS_LIST: `/book-masters`,
    BOOK_MASTERS_CREATE: `/book-masters`,
    BOOK_MASTERS_EDIT  : id =>  `/book-masters/${id}`,
    BOOK_MASTERS_DELETE  : id =>  `/book-masters/${id}`,

    //For Facility Mgmt
    FACILITY_CREATE: `/facilities`,
    FACILITY_EDIT  : id =>  `/facilities/${id}`,
    FACILITY_LIST: `/facilities`,
    FACILITY_RE_ORDER: `/facilities/re-order-display`,
    FACILITY_MANAGE_STATUS: `/facility_manage_status`,
    FACILITY_MANAGE_STATUS_UPDATE : id =>  `/facility_manage_status/${id}`,
    FACILITY_DELETE  : id =>  `/facilities/${id}`,
    FACILITY_PLAN_CREATE :  `/facility_plan`,
    FACILITY_PLAN_EDIT : id =>  `/facility_plan/${id}`,
    FACILITY_PLAN_LIST :  facility_id =>  `/facility_plan?facility_id=${facility_id}`,
    FACILITY_PLAN_DELETE :  id =>  `/facility_plan/${id}`,
    FACILITY_DELETE_IMAGE :  id =>  `/facilities/removeImage/${id}`,

    //For Information Mgmt
    INFORMATION_CREATE: `/information`,
    INFORMATION_LIST: `/information`,
    INFORMATION_EDIT  : id =>  `/information/${id}`,
    INFORMATION_DELETE: id => `/information/${id}`,
    UI_HELPER_INFORMATION : apartment_id => `ui-helper/informations?apartment_id=${apartment_id}`,

    //For Book Reservation Mgmt
    BOOK_RESERVATION_CREATE: `book_reservation`,
    BOOK_RESERVATION_EDIT  : id =>  `/book_reservation/${id}`,
    BOOK_RESERVATION_LIST: `/book_reservation`,
    BOOK_RESERVATION_CANCEL: id =>  `/book_reservation/cancelBooking/${id}`,
    BOOK_RESERVATION_CHECK_UNIQUE:   `/book_reservation/checkUniqueName`,
    //For Book Schedule Mgmt
    BOOKING_SCHEDULE_LIST: `booking_schedule`,

    //For Beacon Mgmt
    BEACON_CREATE : `beacons`,
    BEACON_EDIT  : id =>  `/beacons/${id}`,
    BEACON_DELETE :  id =>  `/beacons/${id}`,
    BEACON_LIST : `beacons`,
    BEACON_HISTORY_LIST : id => `beacons/history?beacon_id=${id}`,
    // For Big Category mgmt
    BIG_CATEGORY_LIST: `chat_bot_category`,
    BIG_CATEGORY_CREATE: `/chat_bot_category`,
    BIG_CATEGORY_EDIT: id =>  `/chat_bot_category/${id}`,
    BIG_CATEGORY_DELETE: id => `/chat_bot_category/${id}`,

    //For Gateway mgmt
    GATEWAY_CREATE : `gateways`,
    GATEWAY_EDIT  : id =>  `/gateways/${id}`,
    GATEWAY_DELETE :  id =>  `/gateways/${id}`,
    GATEWAY_LIST : `gateways`,
    GATEWAY_HISTORY_LIST : id => `gateways/history?gateway_id=${id}`,
    // For Medium Category mgmt
    MEDIUM_CATEGORY_LIST: `chat_bot_category?category_type=1`,
    MEDIUM_CATEGORY_CREATE: `/chat_bot_category`,
    MEDIUM_CATEGORY_EDIT: id =>  `/chat_bot_category/${id}`,
    MEDIUM_CATEGORY_DELETE: id => `/chat_bot_category/${id}`,

    //for Service category mgmt
    SERVICE_CATEGORY_CREATE : `service_category`,
    SERVICE_CATEGORY_EDIT: id =>  `/service_category/${id}`,
    SERVICE_CATEGORY_LIST : `service_category`,
    SERVICE_CATEGORY_RE_ORDER : `service_category/re-order-display`,
    SERVICE_CATEGORY_DELETE: id =>  `/service_category/${id}`,

    // menu mgmt
    MENU_CREATE : `menu`,
    MENU_EDIT: id =>  `/menu/${id}`,
    MENU_LIST : `menu`,
    MENU_DELETE : id =>  `/menu/${id}`,
    MENU_DELETE_IMAGE :  id =>  `/menu/removeImage/${id}`,

    // For Small Category mgmt
    SMALL_CATEGORY_LIST: `chat_bot_category?category_type=2`,
    SMALL_CATEGORY_CREATE: `/chat_bot_category`,
    SMALL_CATEGORY_EDIT: id =>  `/chat_bot_category/${id}`,
    SMALL_CATEGORY_DELETE: id => `/chat_bot_category/${id}`,

    //For Restaurant Mgmt
    RESTAURANT_CREATE : `restaurants`,
    RESTAURANT_EDIT  : id =>  `/restaurants/${id}`,
    RESTAURANT_DELETE :  id =>  `/restaurants/${id}`,
    RESTAURANT_LIST : `restaurants`,
    QR_LINK: id => `/restaurants/qr_code_table/${id}`,

    //For Order List mgmt
     ORDER_LIST : `order_list`,
     ORDER_LIST_EDIT: id => `/order_list/${id}`,
     ORDER_CREATE: `order_list`,
     ORDER_EDIT  : id =>  `/order_list/${id}`,

    // For Answer mgmt
    ANSWER_LIST: `chat_bot_answer`,
    ANSWER_CREATE: `/chat_bot_answer`,
    ANSWER_EDIT: id =>  `/chat_bot_answer/${id}`,
    ANSWER_DELETE: id => `/chat_bot_answer/${id}`,

    //For sale Mgmt
    SALE_LIST: `/sale`,
    SALE_TOTAL: `/sale/total`,
    SALE_LIST_DETAIL: `/sale/list_detail`,
    SALE_LIST_DETAIL_APARTMENT: `/sale/list_detail/apartment`,
    SALE_LIST_DETAIL_APARTMENT_DOWNLOAD: `/sale/list_detail/apartment/download`,
    SALE_LIST_DETAIL_CUSTOMER_DOWNLOAD: `/sale/list_detail/customer/download`,
    SALE_LIST_DETAIL_CUSTOMER: (customer_id, apartment_id) => `/sale/list_detail/customer/${customer_id}/apartment/${apartment_id}`,
    SALE_EDIT: query => `/sale/edit${query}`,
    SALE_UPDATE: id => `/sale/${id}`,
    // For Receipts mgmt
    RECEIPTS_LIST: `receipts`,
    RECEIPTS_CREATE: `/receipts`,
    RECEIPTS_EDIT: id =>  `/receipts/${id}`,
    RECEIPTS_DELETE: id => `/receipts/${id}`,

    //For csv export Mgmt
    CSV_EXPORT: `/csv_download`,
    CSV_EXPORT_LIST_CUSTOMER: `file/download_list_customer`,
    CSV_EXPORT_LIST_RECEIPTS: `file/download_list_receipt`,
    CSV_EXPORT_LIST_RECEIPTS_DETAIL: `file/download_list_receipt_detail`,

    //For Notifies Mgmt
    SETING_NOTIFICATION_MAIL_LIST: `email_notification`,
    SETING_NOTIFICATION_MAIL_CREATE: `email_notification`,
    SETING_NOTIFICATION_MAIL_DELETE: id => `/email_notification/${id}`,

    // For Announcement mgmt
    ANNOUNCEMENTS_LIST: `announcement`,
    ANNOUNCEMENTS_MNG_LIST: `announcement_mng`,
    ANNOUNCEMENTS_MNG_CREATE: `/announcement_mng`,
    ANNOUNCEMENTS_MNG_EDIT: id =>  `/announcement_mng/${id}`,
    ANNOUNCEMENTS_MNG_DELETE: id =>  `/announcement_mng/${id}`,

    //for csv import Mgmt
    CSV_IMPORT_CUSTOMER: `csv_import/customer`,
    CSV_IMPORT_POSTAL_CODE: `csv_import/postal_code`,
    CSV_IMPORT_DELETE_INVOICE: `csv_import/delete_invoice`,
    CSV_IMPORT_INVOICE: `csv_import/invoice_receipt` ,

    //For Transport Car

    TRANSPORTS_CREATE: `/transport-car-master`,
    TRANSPORTS_EDIT  : id =>  `/transport-car-master/${id}`,
    TRANSPORTS_DELETE  : id =>  `/transport-car-master/${id}`,

    //For Transport List mgmt
    TRANSPORTS_LIST : `transport_list`,
    TRANSPORTS_LIST_EDIT: id => `/transport_list/${id}`,

    INVOICE_RECEIPT_LIST : `invoice-receipt`,
    INVOICE_RECEIPT_DELETE: id => `/invoice-receipt/${id}`,
    INVOICE_RECEIPT_EDIT: id => `/invoice-receipt/${id}`,

    INVOICE_RECEIPT_UPLOAD : `invoice_receipt/upload`,
    CUSTOMER_LIST_BY_ID: id => `/customers?filters[status_enabled][equal]=1&perPage=9999&filters[apartment_id][equal]=${id}`,

    // kyykhang ui helper
    UI_HELPER_KYY_STUDENT_ORDER: `ui-helper/kyy-student-orders`,
    UI_HELPER_GET_VALID_STUDENT_ORDERS: `ui-helper/get-valid-student-menus`,
    UI_HELPER_GET_NEWS_CATEGORIES: `ui-helper/get-news-categories`,
    UI_HELPER_GET_RESTAURANT_MENUS: restaurantID => `ui-helper/get-restaurant-menus/${restaurantID}`,

    // kyythang
    MENU_STUDENT_LIST: `menus-student`,
    MENU_STUDENT_CREATE: `/menus-student`,
    MENU_STUDENT_EDIT: id => `/menus-student/${id}`,
    MENU_STUDENT_DELETE: id => `/menus-student/${id}`,

    KYY_RESTAURANT_LIST: `kyy-restaurants`,
    KYY_RESTAURANT_LIST_SELECT: `kyy-restaurants/list-select`,
    KYY_RESTAURANT_CREATE: `/kyy-restaurants`,
    KYY_RESTAURANT_EDIT: id => `/kyy-restaurants/${id}`,
    KYY_RESTAURANT_DELETE: id => `/kyy-restaurants/${id}`,

    KYY_STUDENT_ORDER_LIST: `kyy-student-orders`,
    KYY_STUDENT_ORDER_CREATE: `/kyy-student-orders`,
    KYY_STUDENT_ORDER_EDIT: id => `/kyy-student-orders/${id}`,
    KYY_STUDENT_ORDER_DELETE: id => `/kyy-student-orders/${id}`,
    KYY_STUDENT_ORDER_LIST_SELECT: `/kyy-student-orders/list-select`,

    KYY_EXPORT_ORDER_MENUS: 'kyy-student-orders/export-order-menus',
    KYY_EXPORT_ORDER_LABELS: 'kyy-student-orders/export-order-labels',
    KYY_STUDENT_ORDER_BY_MONTHS: 'kyy-student-orders/statistics-by-months',

    KYY_CATEGORY_LIST: `kyy-categories`,    
    KYY_CATEGORY_LIST_SELECT: `kyy-categories/list-select`,    
    KYY_CATEGORY_CREATE: `/kyy-categories`,
    KYY_CATEGORY_EDIT: id => `/kyy-categories/${id}`,
    KYY_CATEGORY_DELETE: id => `/kyy-categories/${id}`,

    KYY_CATEGORY_MENU_LIST: `kyy-category-menus`,
    KYY_CATEGORY_MENU_CREATE: `/kyy-category-menus`,
    KYY_CATEGORY_MENU_EDIT: id => `/kyy-category-menus/${id}`,
    KYY_CATEGORY_MENU_DELETE: id => `/kyy-category-menus/${id}`,

    KYY_STUDENT_INVOICE_LIST: 'kyy-student-invoices',
    KYY_STUDENT_INVOICE_CREATE: '/kyy-student-invoices/create-month-invoices',
    KYY_STUDENT_INVOICE_SEND: 'kyy-student-invoices/send-month-invoices',

    KYY_TABLE_ORDER_LIST: 'kyy-table-orders',
    KYY_TABLE_ORDER_CREATE: '/kyy-table-orders',
    KYY_TABLE_ORDER_EDIT: id => `/kyy-table-orders/${id}`,
    KYY_TABLE_ORDER_DELETE: id => `/kyy-table-orders/${id}`,
    KYY_TABLE_ORDER_GET_CATEGORY_MENU: `/kyy-table-orders/get-category-menu`,
    KYY_TABLE_ORDER_BY_MONTHS: 'kyy-table-orders/statistics-by-months',

    KYY_STANDARD_ORDER_LIST: `kyy-standard-orders`,
    KYY_STANDARD_STATISTICS: `kyy-standard-orders/statistics-by-months`,
    KYY_STANDARD_3MONTHS: `kyy-standard-orders/statistics-by-3months`,
    KYY_STANDARD_ORDER_EDIT: id => `/kyy-standard-orders/${id}`,

    KYY_NEWS_CATEGORY_LIST: `kyy-news-categories`,    
    KYY_NEWS_CATEGORY_LIST_SELECT: `kyy-news-categories/list-select`,    
    KYY_NEWS_CATEGORY_CREATE: `/kyy-news-categories`,
    KYY_NEWS_CATEGORY_EDIT: id => `/kyy-news-categories/${id}`,
    KYY_NEWS_CATEGORY_DELETE: id => `/kyy-news-categories/${id}`,

    KYY_NEWS_LIST: `kyy-news`,
    KYY_NEWS_CREATE: `/kyy-news`,
    KYY_NEWS_EDIT: id => `/kyy-news/${id}`,
    KYY_NEWS_DELETE: id => `/kyy-news/${id}`,

    KYY_CUSTOMERS_LIST: `kyy-customers`,
    KYY_CUSTOMERS_CREATE: `/kyy-customers`,
    KYY_CUSTOMERS_EDIT: id => `/kyy-customers/${id}`,
    KYY_CUSTOMERS_DELETE: id => `/kyy-customers/${id}`,

    KYY_HOME_SETTING: 'home-setting',
    KYY_SAVE_HOME_SETTING: 'save-home-setting',

    KYY_WEB_SETTING: 'web-setting',
    KYY_SAVE_WEB_SETTING: 'save-web-setting',
};
