import en from "vee-validate/dist/locale/en.json";

en.names = {
  name: 'Name',
  email: 'Email',
  phone: "Phone",
  password: 'Password',
  password_confirm: ' password_confirm',
  role: "Role",
  address: "Address",
  fax: "FAX",
  new_password: "New password",
  new_password_confirm: "New password (confirmation)",
  current_password: "Current password",
  current_email: "Current email address",
  new_email: "New email address",
  start_date: "Start date",
  end_date: "End date",
  login_email: "This email address",
  price: "Price",
}

en.messages = {
  ...en.messages,
  "alpha": "{_field_} can only use alphabets.",
  "alpha_num": "{_field_} can only use alphanumeric characters.",
  "alpha_dash": "{_field_} can only use alphanumeric characters, hyphens and underscores.",
  "alpha_spaces": "{_field_} can only use alphabets and spaces.",
  "between": "{_field_} must be between {min} and {max}.",
  "confirmed": "{_field_} does not match.",
  "digits": "{_field_} must be {length} digits.",
  "dimensions": "{_field_} must be within width {width} px, height {height} px.",
  "email": "This email address is not a valid email address.",
  "excluded": "{_field_} is an invalid value.",
  "ext": "{_field_} is not a valid file format.",
  "image": "{_field_} is not a valid image format.",
  "integer": "{_field_} can only be integers.",
  "is": "{_field_} does not match.",
  "is_not": "{_field_} is invalid.",
  "length": "Enter in half-width number {length} digit format.",
  "max_value": "Enter the number below {max} here.",
  "max": "{_field_} must be within {length} characters.",
  "mimes": "{_field_} is not a valid file format.",
  "min_value": "Enter a number greater than or equal to {min} here.",
  "min": "{_field_} must be at least {length} characters.",
  "numeric": "{_field_} can only be numbers.",
  "oneOf": "{_field_} is not a valid value.",
  "regex": "{_field_} is not in the correct format.",
  "required": "This is a required field.",
  "required_if": "This is a required field.",
  "size": "{_field_} must be within {size} KB.",
  "double": "{_field_} field must be a valid decimal number.",
  required_noname: 'This is a required field. ',
  password: 'Password must be 8 to 20 single-byte alphanumeric characters and must include all uppercase, lowercase, and numbers. ',
  phone: 'Please enter the phone number in 10-digit or 11-digit half-width numbers.',
  phone_home: 'Enter your home number in 10-digit half-width numbers. ',
  phone_mobile: 'Enter the mobile number in the format of 11 single-byte numbers. ',
  fax: "Please enter the fax in the format of 10 single-byte numbers.",
  ja_postal_code: "Please enter a valid zip code.",
  email_unique: "This email address is already in use."
}

export default en
