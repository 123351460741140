import {ROUTES} from "@constants"
import i18n from "@/lang/i18n";

export default [
  {
    path: 'web-setting',
    name: ROUTES.ADMIN.KYY_WEB_SETTING,
    meta: {
      title: 'WebSetting',
      breadcrumb: [
        {
          'label': 'breadcrumbs.MasterWebSetting',
        },
      ],
      activeKey: 'admin.web_setting'
    },
    component: () => import("@pages/admin/kyy_web_setting/WebSetting"),
  },
];
