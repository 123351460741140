import {ROUTES} from "@constants"
import i18n from "@/lang/i18n";

export default [
  {
    path: 'kyy-customers',
    name: ROUTES.ADMIN.KYY_CUSTOMERS_LIST,
    meta: {
      title: 'Quản lý khách hàng',
      breadcrumb: [
        {
          'label': 'breadcrumbs.MasterCustomersList',
        },
      ],
      activeKey: 'admin.kyy-customers.list'
    },
    component: () => import("@pages/admin/kyy_customers/CustomerListPage"),
  },
  {
    path: 'kyy-customers/create',
    name: ROUTES.ADMIN.KYY_CUSTOMERS_CREATE,
    meta: {
      title: 'Quản lý khách hàng',
      breadcrumb: [
        {
          'label': 'breadcrumbs.MasterCustomersList',
          'href': '/admin/kyy-customers',
        },
        {
          'label': 'breadcrumbs.MasterCustomersCreate',
        },
      ],
      activeKey: 'admin.kyy-customers.create'
    },
    component: () => import("@pages/admin/kyy_customers/CustomerCreateOrEdit"),
  },
  {
    path: 'kyy-customers/:id/edit',
    name: ROUTES.ADMIN.KYY_CUSTOMERS_EDIT,
    meta: {
      title: 'Quản lý khách hàng',
      breadcrumb: [
        {
          'label': 'breadcrumbs.MasterCustomersList',
          'href': '/admin/kyy-customers',
        },
        {
          'label': 'breadcrumbs.MasterCustomersUpdate',
        },
      ],
      activeKey: 'admin.kyy-customers.edit'
    },
    component: () => import("@pages/admin/kyy_customers/CustomerCreateOrEdit"),
  },
];
