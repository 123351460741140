import { ROUTES } from "@constants";
import userRoute from "@routes/admin/userRoute";
import authRoute from "@routes/admin/authRoute";
import authAccess from "@routes/middlewares/authAccess";
import authSettingMiddleware from "@routes/middlewares/authSettingMiddleware";
import axiosBaseNew from "@routes/middlewares/axiosBaseNew";
import mansionRoute from "./mansionRoute";
import customerRoute from "./customerRoute";
import customerVitalRoute from "./customerVitalRoute";
import customerMealRoute from "./customerMealRoute";
import bookMasterRoute from "./bookMasterRoute";
import facilityRoute from "./facilityRoute";
import informationRoute from "./informationRoute";
import planRoute from "./planRoute";
import reservation from "./reservationRoute";
import beacons from "./beaconsRoute";
import bigCategoryListRoute from "./bigCategoryListRoute";
import gateways from "./gatewayRoute";
import mediumCategoryListRoute from "./mediumCategoryListRoute";
import serviceCategory from "./serviceCateRoute";
import menu from "./menuRoute";
import smallCategoryListRoute from "./smallCategoryListRoute";
import restaurants from "./restaurantsRoute";
import answerRoute from "./answerRoute";
import saleRoute from "./saleRoute";
import receiptsRoute from "./receiptsRoute";
import csvDownloadRoute from "./csvDownloadRoute";
import notifiesRoute from "./notifiesRoute";
import announcements from "./announcementRoute";
import transports from "./transportRoute";
import invoiceReceipt from "@routes/admin/invoiceReceipt";
import roomserviceOrderRoute from "./roomserviceOrderRoute";
import menuStudentRoute from "./menuStudentRoute";
import kyyRestaurantRoute from "@routes/admin/kyyRestaurantRoute";
import kyyStudentOrderRoute from "@routes/admin/kyyStudentOrderRoute";
import kyyOrderExportRoute from "./kyyOrderExportRoute";
import kyyInvoiceSendingRoute from "@routes/admin/kyyInvoiceSendingRoute";
import kyyCategoryRoute from "./kyyCategoryRoute";
import kyyCategoryMenuRoute from "./kyyCategoryMenuRoute";
import kyyTableOrderRoute from "./kyyTableOrderRoute";
import kyyStandardOrderRoute from "./kyyStandardOrderRoute";
import kyyNewsCategoryRoute from "./kyyNewsCategoryRoute";
import kyyNewsRoute from "./kyyNewsRoute";
import kyyCustomersRoute from "./kyyCustomersRoute";
import kyyHomeSettingRoute from "./kyyHomeSettingRoute";
import kyyWebSettingRoute from "./kyyWebSettingRoute";
export default [
  ...authRoute,
  {
    path: '/admin',
    meta: {
      middlewares: [authSettingMiddleware('admin'), axiosBaseNew, authAccess],
    },
    component: () => import('@layouts/admin/MasterLayout.vue'),
    children: [
      {
        path: 'profile',
        name: ROUTES.ADMIN.PROFILE,
        meta: { breadcrumb: 'breadcrumbs.Profile', activeKey: 'admin.profile' },
        component: () => import("@pages/base_auths/ProfilePage"),
      },
      {
        path: 'dashboard',
        name: ROUTES.ADMIN.DASHBOARD,
        meta: { breadcrumb: 'breadcrumbs.Dashboard' },
        component: () => import("@pages/admin/dashboard/DashboardPage"),
      },
      {
        path: 'booking-customer',
        name: ROUTES.ADMIN.BOOKING_CUSTOMER_LIST,
        component: () => import("@pages/admin/booking/BookingCustomerListPage"),
      },
      {
        path: 'booking-schedule',
        name: ROUTES.ADMIN.BOOKING_SCHEDULE_LIST,
        meta: {
          title: '予約スケジュール',
          breadcrumb: [
            {
              'label': 'breadcrumbs.Booking Schedule List',
            },
          ],
          activeKey: 'admin.information.list'
        },
        component: () => import("@pages/admin/booking/BookingScheduleListPage"),
      },
      {
        path: 'gateways',
        name: ROUTES.ADMIN.GATEWAY_LIST,
        meta: {
          title: 'ゲートウェイ管理',
          breadcrumb: [
            {
              'label': 'breadcrumbs.Gateway List',
            },
          ],
          activeKey: 'admin.information.list'
        },
        component: () => import("@pages/admin/gateways/GatewayListPage"),
      },
      {
        path: 'categories',
        name: ROUTES.ADMIN.CATEGORY_LIST,
        meta: {
          title: 'カテゴリ管理',
          breadcrumb: [
            {
              'label': 'breadcrumbs.Service Category List',
            },
          ],
          activeKey: 'admin.information.list'
        },
        component: () => import("@pages/admin/service_category/ServiceCategoryListPage"),
      },
      {
        path: 'menu',
        name: ROUTES.ADMIN.MENU_LIST,
        meta: {
          title: 'メニュー管理',
          breadcrumb: [
            {
              'label': 'breadcrumbs.Menu List',
            },
          ],
          activeKey: 'admin.menu.list'
        },
        component: () => import("@pages/admin/menu/MenuListPage"),
      },
      {
        path: 'download-csv',
        name: ROUTES.ADMIN.DOWNLOAD_CSV,
        meta: {
          title: 'CSVダウンロード',
          breadcrumb: [
            {
              'label': 'breadcrumbs.Download CSV',
            },
          ],
          activeKey: 'admin.csv.download'
        },
        component: () => import("@pages/admin/csv/DownloadCsv"),
      },
      {
        path: 'upload-csv',
        name: ROUTES.ADMIN.UPLOAD_CSV,
        meta: {
          title: 'CSVアップロード',
          breadcrumb: [
            {
              'label': 'breadcrumbs.Upload CSV',
            },
          ],
          activeKey: 'admin.csv.upload'
        },
        component: () => import("@pages/admin/csv/UploadCsv"),
      },
      /*{
        path: 'notifies',
        name: ROUTES.ADMIN.SETING_NOTIFICATION_MAIL,
        meta: {
          breadcrumb: [
            {
              'label': 'breadcrumbs.Notifies List',
            },
          ],
          activeKey: 'admin.notifies.list'
        },
        component: () => import("@pages/admin/notifies/NotifiesList"),
      },*/
      {
        path: 'form',
        name: "form_page",
        meta: { activeKey: 'admin.users.all_user' },
        component: () => import("@pages/admin/FormPage"),
      },
      {
        path: 'list',
        name: "list_page",
        meta: { activeKey: 'admin.users.all_user' },
        component: () => import("@pages/admin/ListPage"),
      },
      ...userRoute,
      ...mansionRoute,
      ...customerRoute,
      ...bookMasterRoute,
      ...facilityRoute,
      ...informationRoute,
      ...planRoute,
      ...reservation,
      ...beacons,
      ...bigCategoryListRoute,
      ...gateways,
      ...mediumCategoryListRoute,
      ...serviceCategory,
      ...menu,
      ...smallCategoryListRoute,
      ...answerRoute,
      ...saleRoute,
      ...csvDownloadRoute,
      ...notifiesRoute,
      ...announcements,
      ...transports,
      ...invoiceReceipt,
      ...roomserviceOrderRoute,
      ...menuStudentRoute,
      ...kyyRestaurantRoute,
      ...kyyStudentOrderRoute,
      ...kyyOrderExportRoute,
      ...kyyInvoiceSendingRoute,
      ...kyyCategoryRoute,
      ...kyyCategoryMenuRoute,
      ...kyyTableOrderRoute,
      ...kyyStandardOrderRoute,
      ...kyyNewsCategoryRoute,
      ...kyyNewsRoute,
      ...kyyCustomersRoute,
      ...kyyHomeSettingRoute,
      ...kyyWebSettingRoute
    ],
  },
]
