import {ROUTES} from "@constants"
import i18n from "@/lang/i18n";

export default [
  {
    path: 'page-news',
    name: ROUTES.ADMIN.KYY_NEWS_LIST,
    meta: {
      title: 'News',
      breadcrumb: [
        {
          'label': 'breadcrumbs.MasterNewsList',
        },
      ],
      activeKey: 'admin.news.list'
    },
    component: () => import("@pages/admin/kyy_news/NewsListPage"),
  },
  {
    path: 'page-news/create',
    name: ROUTES.ADMIN.KYY_NEWS_CREATE,
    meta: {
      title: 'News',
      breadcrumb: [
        {
          'label': 'breadcrumbs.MasterNewsList',
          'href': '/admin/news',
        },
        {
          'label': 'breadcrumbs.MasterNewsCreate',
        },
      ],
      activeKey: 'admin.news.create'
    },
    component: () => import("@pages/admin/kyy_news/NewsCreateOrEdit"),
  },
  {
    path: 'page-news/:id/edit',
    name: ROUTES.ADMIN.KYY_NEWS_EDIT,
    meta: {
      title: 'News',
      breadcrumb: [
        {
          'label': 'breadcrumbs.MasterNewsList',
          'href': '/admin/news',
        },
        {
          'label': 'breadcrumbs.MasterNewsUpdate',
        },
      ],
      activeKey: 'admin.news.edit'
    },
    component: () => import("@pages/admin/kyy_news/NewsCreateOrEdit"),
  },
];
